import {IMappedDataResponse, Languages} from '../types/types';

export const LOAD_CATEGORIES_START = 'LOAD_CATEGORIES_START';
export const LOAD_CATEGORIES_FINISH = 'LOAD_CATEGORIES_FINISH';
export const LOAD_CATEGORIES_FAIL = 'LOAD_CATEGORIES_FAIL';

export type LOAD_CATEGORIES_START_TYPE = typeof LOAD_CATEGORIES_START;
export type LOAD_CATEGORIES_FINISH_TYPE = typeof LOAD_CATEGORIES_FINISH;
export type LOAD_CATEGORIES_FAIL_TYPE = typeof LOAD_CATEGORIES_FAIL;

export interface ILoadCategoriesStartAction {
    type: LOAD_CATEGORIES_START_TYPE;
    payload: Languages;
}

export interface ILoadCategoriesFinishAction {
    type: LOAD_CATEGORIES_FINISH_TYPE;
    payload: IMappedDataResponse;
}

export interface ILoadCategoriesFailAction {
    type: LOAD_CATEGORIES_FAIL_TYPE;
    payload: number;
    error: boolean;
}

export type CategoriesAction = ILoadCategoriesStartAction | ILoadCategoriesFinishAction | ILoadCategoriesFailAction;
