import {IContentInfo} from '../types/types';

import {checkContentProps} from './checkContentProps';
import {getRandomNumber} from './getRandomNumber';

export function getRandomContentItem(contents: IContentInfo[], excludedId: number): number[] {
    const filteredContents: IContentInfo[] = contents.filter((content) =>
        checkContentProps(content) && content.id !== excludedId);
    const idArray: number[] = [];

    filteredContents.forEach((content: IContentInfo) => {
        idArray.push(content.id);
    });

    return idArray.sort(getRandomNumber);
}
