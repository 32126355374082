import {Languages} from '../types/types';

export const CATEGORIES_URL: string = '/api/compact-auto';

export const DEFAULT_LANG = Languages.ru;
export const HOME: string = '/';
export const TEXTCONTENT_URL_BASE: string = '/gate/d/';

// TODO изменить альяс на текстовки и впс
export const TEXT_URL_BASE: string = '/text/tj-babilon-m-mens-live/';
export const OFFER_URL: string = TEXT_URL_BASE + 'oferta/';
export const FOOTER_URL: string = TEXT_URL_BASE + 'footer/';
export const SUBSCRIPTION_URL: string = TEXT_URL_BASE + 'pricepage/';
// export const AUTH_LINK_TJ = 'http://mens.babilon-m.tj/lp/flow/tj-babilon-m-mens-live?source=service';
export const AUTH_LINK_RU = 'http://mens.babilon-m.tj/lp/flow/tj-babilon-m-mens-live?source=service';
export const AUTH_LINK_EN = 'http://mens.babilon-m.tj/lp/flow/tj-babilon-m-mens-live?source=service';

// aliases
export const PRESS_ALIAS = 'pressa';
export const MUSIC_TAG_TYPE = 'music_import_';
export const COMMON_CONTENT_CARD_TYPE = 'content';
export const MUSIC_CONTENT_CARD_TYPE = 'music';
export const JOURNALS_TAG_TYPE = 'ji_journal_';
export const RUS_LANG = 'ru';
// export const TJ_LANG = 'tj';
export const ENG_LANG = 'eng';

// numbers constant
export const AMOUNT_OF_CONTENTITEMS_FOR_MAINPAGE = 8;
