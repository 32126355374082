import React from 'react';

import {ReactComponent as AutoIcon} from '../Icons/auto.svg';
import {ReactComponent as FoodIcon} from '../Icons/food.svg';
import {ReactComponent as GadgetsIcon} from '../Icons/gadgets.svg';
import {ReactComponent as MagazinesIcon} from '../Icons/magazines.svg';
import {ReactComponent as MusicIcon} from '../Icons/music.svg';
import {ReactComponent as RelaxetionIcon} from '../Icons/relaxation.svg';
import {ReactComponent as SpaceIcon} from '../Icons/space.svg';
import {ReactComponent as SportIcon} from '../Icons/sport.svg';
import {ReactComponent as StyleIcon} from '../Icons/style.svg';

export interface IMenuIcon {
    category: string;
}

export function MenuIcon({ category }: IMenuIcon) {
    switch (category) {
        case 'avto':
            return <AutoIcon />;
        case 'pitanie':
            return <FoodIcon />;
        case 'gadzheti':
            return <GadgetsIcon />;
        case 'pressa':
            return <MagazinesIcon />;
        case 'otdikh':
            return <RelaxetionIcon />;
        case 'kosmos':
            return <SpaceIcon />;
        case 'sport':
            return <SportIcon />;
        case 'stil':
            return <StyleIcon />;
        case 'music':
            return <MusicIcon />;
        default:
            return null;
    }
}
