import React, {Component, ReactNode} from 'react';

import {ClassNameFormatter, cn} from '@bem-react/classname';
import {classnames} from '@bem-react/classnames';
import {IClassNameProps} from '@bem-react/core';
import {Translation} from 'react-i18next';

import {ContentType, ITag} from '../../types/types';

import './ContentDescription.css';

export interface IContentDescription extends IClassNameProps {
    tags: ITag[];
    title: string;
    link: string;
    theme?: string;
    type: string;
    order?: string;
    size: string;
    page: string;
}

const contentDescription: ClassNameFormatter = cn('ContentDescription');

export class ContentDescription extends Component<IContentDescription> {
    getContentType = (contentType: string): ReactNode => {
        if (contentType === ContentType.type1) {
            return (
                <Translation>
                    {
                        (t, { i18n }) =>
                            <div>{t('Чтиво')}</div>
                    }
                </Translation>
            );
        } else if (contentType === ContentType.type2 || contentType === ContentType.type4) {
            return (
                <Translation>
                    {
                        (t, { i18n }) =>
                            <div>{t('Видео')}</div>
                    }
                </Translation>
            );
        } else {
            return null;
        }
    };

    getContentTags = (contentTags: ITag[]): ReactNode[] => {
        return (
            contentTags.map((contentTag: ITag, index) => {

                if (index === 0 && contentTags.length > 1) {
                    return (
                        <Translation key={index}>
                            {
                                () =>
                                    <div className={contentDescription('Tags')} key={index}>
                                        {`${contentTag.display_name}, `}
                                    </div>
                            }
                        </Translation>
                    )
                }

                return (
                    <Translation key={index}>
                        {
                            () =>
                                <div className={contentDescription('Tags')} key={index}>
                                    {contentTag.display_name}
                                </div>
                        }
                    </Translation>
                )
            })
        )
    };

    render() {
        const {tags, title, theme, type, size, page} = this.props;
        const contentTags = this.getContentTags(tags);
        const contentType = this.getContentType(type);
        return (
            <div
                className={classnames(contentDescription({theme, type, size, ofPage: page}), this.props.className)}>
                <div className={contentDescription('LabelWrap')}>
                    <div className={contentDescription('TagsWrap')}>
                        <div className={contentDescription('Tags')}>
                            {this.props.title}
                        </div>
                    </div>
                </div>
                <div className={contentDescription('Title')}>
                    <div className={contentDescription('TitleText')}>{title}</div>
                </div>
            </div>
        )
    }
}
