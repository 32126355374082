import React, {Component} from 'react';

import {ClassNameFormatter, cn} from '@bem-react/classname';
import {IClassNameProps} from '@bem-react/core';

import {ICategory, Languages, PageType} from '../../types/types';
import {HeaderContainer} from '../Header/HeaderContainer';
import {HorizontalPreloader} from '../Preloader/HorizontalPreloader';

import './OfferPage.css';

const offerPage: ClassNameFormatter = cn('OfferPage');

export interface IOfferPageStateProps extends IClassNameProps {
    offerText?: string;
    offerTexts: {
        [key: string]: string;
    };
    error?: boolean;
    textLoaded: boolean;
    language: Languages;
    categoriesData?: ICategory[];
}

export interface IOfferPageDispatchProps {
    onLoad(lang: Languages): void;
}

export interface IOfferPageProps extends IOfferPageStateProps, IOfferPageDispatchProps {
}

export class OfferPage extends Component <IOfferPageProps> {
    componentDidMount() {
        window.scrollTo(0, 0);
        const lang: Languages = this.props.language;
        this.props.offerTexts[lang] === undefined && this.props.onLoad(lang);
    }

    componentDidUpdate(prevProps: IOfferPageProps) {
        window.scrollTo(0, 0);
        const lang: Languages = this.props.language;
        this.props.language !== prevProps.language && this.props.offerTexts[lang] === undefined &&
        this.props.onLoad(this.props.language);
    }

    createMarkup = (jsonString: any) => {
        return {__html: jsonString};
    };

    renderText = () => {
        if (this.props.error) {
            return null;
        }
        if (this.props.textLoaded && this.props.offerText) {
            return (
                <div className={offerPage('Text')} dangerouslySetInnerHTML={this.createMarkup(this.props.offerText)} />
            );
        } else {
            return (<HorizontalPreloader />);
        }
    };

    public render() {
        if (this.props.categoriesData !== undefined) {
            return (
                <div className={offerPage()}>
                    <HeaderContainer
                        className={offerPage('Header')}
                        categoriesData={this.props.categoriesData}
                        page={PageType.typeOffer}
                        onChange={(lang: Languages) => {
                        }}/>
                    {this.renderText()}
                </div>
            );
        }
        return <HorizontalPreloader/>
    }
}
