import React, {useEffect, useState} from 'react';

import {ClassNameFormatter, cn} from '@bem-react/classname';
import Cookie from 'js-cookie';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';

import {setPlayerInfo, setPlayerState} from '../../actions/playerAction';
import {AUTH_LINK_EN, AUTH_LINK_RU} from '../../constants/constants';
import {IAppStore, ICategory, IContentInfo, Languages} from '../../types/types';
import {Equalizer} from '../Equalizer/Equalizer';
import placeholder from '../Icons/placeholder_music.svg';
import play from '../Icons/play.svg';
import {getNearNumber, getNextTopNumber, getTrackInfo} from '../MusicItemPreview/MusicItemPreview';

import './Player.css';

const player: ClassNameFormatter = cn('Player');

interface IPlayerProps {
    handlePlay(): void;
    handlePause(): void;
}

const getAuthLink = (lang: string) => {
    let authLink: string;
    switch (lang) {
        // case (Languages.tj):
        //     authLink = AUTH_LINK_TJ;
        //     break;
        case (Languages.en):
            authLink = AUTH_LINK_EN;
            break;
        case (Languages.ru):
        default:
            authLink = AUTH_LINK_RU;
    }
    return authLink;
}

export const Player = (props: IPlayerProps) => {
    const location = useLocation();
    const { pathname } = location;
    const [stoppedInPressPage, setStoppedInPressPage] = useState(false);
    const { handlePlay, handlePause } = props;

    const state: IAppStore = useSelector((st: IAppStore) => st);

    const dispatch = useDispatch();

    const {
        playerInfo: {
            trackNumber,
            musicAlias,
            isPlaying,
            topNumber,
        },
        section: {
            musicCategories,
            musicTops,
            authorized,
        },
        lang,
    } = state;

    const isPressContentPage = pathname.indexOf('pressa') > -1 && pathname.indexOf('content') > -1;

    useEffect(() => {
        if (isPressContentPage && isPlaying) {
            handlePause();
            setStoppedInPressPage(true);
        }
        if (!isPressContentPage && stoppedInPressPage) {
            handlePlay();
            setStoppedInPressPage(false);
        }
    }, [pathname]);

    const currentMusicCategory: ICategory = musicCategories
        .filter((category: ICategory) => category.alias === musicAlias)[0];
    const playlist: IContentInfo[] | [] = (currentMusicCategory && currentMusicCategory.contents.length)
        ? currentMusicCategory.contents : [];

    const { playerName, trackName, previewLink } = getTrackInfo(playlist, trackNumber);

    const previewStyle = {
        backgroundImage: previewLink || `url(${placeholder})`,
        backgroundSize: previewLink ? 'cover' : 'contain',
        backgroundColor: '#dbdbdb',
        backgroundPositionY: '50%, 50%',
        backgroundPositionX: '50%, 50%',
        backgroundRepeat: 'no-repeat',
    };

    const authLink = getAuthLink(lang);

    const togglePlay = () => {
        if (!authorized) {
            document.location.href = authLink;
        }
        if (isPlaying) {
            handlePause();
        } else {
            handlePlay();
        }
    }

    const setNextTrack = () => {
        if (!authorized) {
            document.location.href = authLink;
        }
        dispatch(setPlayerState({
            isPlaying: true,
        }));
        if (trackNumber === getNearNumber(trackNumber, playlist.length, 1)) {
            const nextTopNumber = getNextTopNumber(topNumber, musicTops);
            const nextMusicAlias = musicTops[nextTopNumber].alias;
            dispatch(setPlayerInfo({
                trackNumber: 0,
                musicAlias: nextMusicAlias,
                topNumber: nextTopNumber,
            }));
        } else {
            dispatch(setPlayerInfo({
                trackNumber: getNearNumber(trackNumber, playlist.length, 1),
                musicAlias,
                topNumber,
            }));
        }
    }

    const setPrevTrack = () => {
        if (!authorized) {
            document.location.href = authLink;
        }
        dispatch(setPlayerState({
            isPlaying: true,
        }));
        dispatch(setPlayerInfo({
            trackNumber: getNearNumber(trackNumber, playlist.length, -1),
            musicAlias,
            topNumber,
        }));
    }

    useEffect(() => {
        const track = Number(Cookie.get('trackNumber'));
        const alias = Cookie.get('musicAlias');
        const top = musicTops.findIndex((musicTop) => musicTop.alias === alias);
        if (alias !== undefined && track !== undefined && top > -1) {
            dispatch(setPlayerInfo({
                trackNumber: track,
                musicAlias: alias,
                topNumber: top,
            }));
        }
    }, [musicTops]);

    useEffect(() => {
        if (musicAlias && trackNumber !== undefined) {
            Cookie.set('trackNumber', trackNumber.toString());
            Cookie.set('musicAlias', musicAlias);
        }
    }, [musicAlias, trackNumber]);

    return (
        <>
            {musicAlias && !isPressContentPage ? <div className={player()}>
                <div className={player('Inner')}>
                    <div style={previewStyle} className={player('Preview')}/>
                    <div className={player('Controls')}>
                        <button
                            onClick={setPrevTrack}
                            type='button'
                            className={player('PrevTrack')}
                        />
                        <button
                            type='button'
                            onClick={togglePlay}
                            className={player('Play')}
                        >
                            {isPlaying ? <Equalizer /> : <img src={play} alt='' />}
                        </button>
                        <button
                            onClick={setNextTrack}
                            type='button'
                            className={player('NextTrack')}
                        />
                    </div>
                    <div className={player('TrackInfoInner')}>
                        <div
                            className={player('TrackName')}>
                                <span
                                    className={player('TrackNameInner')}>
                                    {trackName}
                                </span>
                        </div>
                        <div
                            className={player('PlayerName')}
                        >
                                <span
                                    className={player('PlayerNameInner')}>
                                    {playerName}
                                </span>
                        </div>
                    </div>
                </div>
            </div> : null}
        </>
    );
};
