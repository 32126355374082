import Cookies from 'js-cookie';
import {ActionsObservable, combineEpics, ofType, StateObservable} from 'redux-observable';
import {Observable} from 'rxjs/internal/Observable';
import {EMPTY} from 'rxjs/internal/observable/empty';
import {from} from 'rxjs/internal/observable/from';
import {of} from 'rxjs/internal/observable/of';
import {catchError, map, mergeMap, tap} from 'rxjs/operators';

import {
    CategoriesAction,
    ILoadCategoriesFailAction,
    ILoadCategoriesFinishAction,
    ILoadCategoriesStartAction,
    LOAD_CATEGORIES_FAIL,
    LOAD_CATEGORIES_FINISH,
    LOAD_CATEGORIES_START,
} from '../actions/categoriesAction';
import {
    CHECK_AUTHORIZATION,
    ICheckAuthorizationAction,
} from '../actions/checkAuthorizationtAction';
import {
    ContentAction,
    ILoadContentFailAction,
    ILoadContentFinishAction,
    ILoadContentStartAction,
    LOAD_CONTENT_FAIL, LOAD_CONTENT_FINISH, LOAD_CONTENT_START,
} from '../actions/contentAction';
import {
    ERROR_MESSAGE,
    IErrorMessageAction,
} from '../actions/errorAction';
import {
    FooterAction, ILoadFooterFailAction,
    ILoadFooterFinishAction,
    ILoadFooterStartAction, LOAD_FOOTER_FAIL, LOAD_FOOTER_FINISH,
    LOAD_FOOTER_START,
} from '../actions/footerAction';
import {
    CHANGE_LANGUAGE_FINISH,
    CHANGE_LANGUAGE_START,
    IChangeLanguageStartAction,
    LanguageAction,
} from '../actions/languageAction';
import {
    ILoadOfferFailAction,
    ILoadOfferFinishAction,
    ILoadOfferStartAction,
    LOAD_OFFER_FAIL,
    LOAD_OFFER_FINISH,
    LOAD_OFFER_START,
    OfferAction,
} from '../actions/offerAction';
import {
    ILoadManifestFailAction,
    ILoadManifestFinishAction,
    ILoadManifestStartAction,
    LOAD_MANIFEST_START, loadManifestFail, loadManifestFinish,
    ManifestAction,
} from '../actions/pressContentAction';
import {
    ILoadSubscriptionFailAction,
    ILoadSubscriptionFinishAction,
    ILoadSubscriptionStartAction, LOAD_SUBSCRIPTION_FAIL, LOAD_SUBSCRIPTION_FINISH,
    LOAD_SUBSCRIPTION_START,
    SubscriptionAction,
} from '../actions/subscriptionAction';
import {
    getData, getManifest,
    getText,
    IDataResponse, IManifestResponse,
    ITextResponse,
} from '../api';
import {
    AUTH_LINK_EN,
    AUTH_LINK_RU,
    CATEGORIES_URL,
    FOOTER_URL, OFFER_URL,
    SUBSCRIPTION_URL,
    TEXTCONTENT_URL_BASE,
} from '../constants/constants'
import {getCurrentLang, setLang} from '../helpers/currentLanguageHelper';
import {IAppStore, ICategory, IContentInfo, IMusicTop, Languages} from '../types/types';

const loadCategoriesEpic = (action$: ActionsObservable<CategoriesAction>) =>
    action$.pipe(
    ofType<CategoriesAction, ILoadCategoriesStartAction>(LOAD_CATEGORIES_START),
    mergeMap((a: ILoadCategoriesStartAction) => {
        const lang = a.payload;
        let param;

        switch (lang) {
            // case (Languages.tj):
            //     param = 'tjk';
            //     break;
            case (Languages.en):
                param = 'en';
                break;
            case (Languages.ru):
            default:
                param = 'ru';
        }

        const categoriesUrl = `${CATEGORIES_URL}?lang=${param}`;

        return from(getData(categoriesUrl)).pipe(
            map(
                (data: IDataResponse): ILoadCategoriesFinishAction
                    | ILoadCategoriesFailAction
                    | IErrorMessageAction => {
                if (data.status >= 400) {
                    return {
                        type: LOAD_CATEGORIES_FAIL,
                        payload: data.status,
                        error: true,
                    };
                }

                if (data.json) {
                    const {name, authorized, categories} = data.json;

                    const categoriesMusicData: ICategory[] = categories
                        .filter((category: ICategory) => category.contents
                        .filter((content: IContentInfo) => content.meta_params
                            && content.meta_params.content_type_id === 5544).length)
                        .map((category: ICategory) => {
                            const musicContent: IContentInfo[] = category.contents
                                .filter((content: IContentInfo) => content.meta_params
                                && content.meta_params.content_type_id === 5544);
                            return {
                                ...category,
                                contents: musicContent,
                            }
                        });

                    const categoriesWithoutMusic = categories.map((category: ICategory) => {
                        if (category.contents
                            .filter((content: IContentInfo) => content.meta_params
                                && content.meta_params.content_type_id === 5544).length) {
                            const contentWithoutMusic: IContentInfo[] = category.contents
                                .filter((content: IContentInfo) => !content.meta_params
                                    || content.meta_params.content_type_id !== 5544);
                            return {
                                ...category,
                                contents: contentWithoutMusic,
                            }
                        }
                        return category;
                    });

                    const musicTops: IMusicTop[] | [] = (categoriesMusicData && categoriesMusicData.length)
                        ? categoriesMusicData.map((category: ICategory) => {
                        return { alias: category.alias };
                    }) : [];

                    return {
                        type: LOAD_CATEGORIES_FINISH,
                        payload: {
                            name,
                            authorized,
                            categories: categoriesWithoutMusic,
                            musicCategories: categoriesMusicData,
                            musicTops,
                            lang,
                        },
                    }
                }

                return {
                    type: ERROR_MESSAGE,
                    message: 'LOAD_CATEGORIES fail',
                };
            }),
        )
    }),
    catchError((error) => of({
        type: ERROR_MESSAGE,
        message: `LOAD_CATEGORIES fail ${error.message}`,
    })),
);

const loadManifestEpic = (action$: ActionsObservable<ManifestAction>, state$: StateObservable<IAppStore>) =>
    action$.pipe(
        ofType<ManifestAction, ILoadManifestStartAction>(LOAD_MANIFEST_START),
        mergeMap((a: ILoadManifestStartAction) => {
            const hash = a.payload.hash;
            Cookies.set('lastUrl', a.payload.routeUrl);

            const manifestUrl = TEXTCONTENT_URL_BASE + a.payload.hash + '/manifest.json';

            const lang = getCurrentLang();
            let authLink: string;
            switch (lang) {
                // case (Languages.tj):
                //     authLink = AUTH_LINK_TJ;
                //     break;
                case (Languages.en):
                    authLink = AUTH_LINK_EN;
                    break;
                case (Languages.ru):
                default:
                    authLink = AUTH_LINK_RU;
            }

            if (!state$.value.section.authorized) {
                document.location.href = authLink;
                return EMPTY;
            }

            return from(getManifest(manifestUrl)).pipe(
                mergeMap((data: IManifestResponse)
                    : Observable<ILoadManifestFinishAction> | Observable<ILoadManifestFailAction> => {
                    if ((data.status === 401) || (data.status === 403) || (data.status === 418)) {
                        document.location.href = authLink;
                    }

                    if ((data.status >= 400) && (data.status !== 403)
                        && (data.status !== 401) && (data.status !== 418)) {
                        return of(loadManifestFail(data.status));
                    }

                    if (data.json) {
                        return of(loadManifestFinish(data.json, hash));
                    }
                    return EMPTY;
                }),
            )
        }),
        catchError((error) => of({
            type: ERROR_MESSAGE,
            message: `LOAD_MANIFEST fail ${error.message}`,
        })),
    );

const loadContentEpic = (action$: ActionsObservable<ContentAction>) =>
    action$.pipe(
        ofType<ContentAction, ILoadContentStartAction>(LOAD_CONTENT_START),
        mergeMap((a: ILoadContentStartAction) => {
            const hash = a.payload.hash;
            Cookies.set('lastUrl', a.payload.routeUrl);
            const contentUrl = TEXTCONTENT_URL_BASE + a.payload.hash;
            const lang = getCurrentLang();
            let authLink: string;
            switch (lang) {
                // case (Languages.tj):
                //     authLink = AUTH_LINK_TJ;
                //     break;
                case (Languages.en):
                    authLink = AUTH_LINK_EN;
                    break;
                case (Languages.ru):
                default:
                    authLink = AUTH_LINK_RU;
                    break;
            }
            return from(getText(contentUrl)).pipe(
                map((data: ITextResponse): ILoadContentFinishAction | ILoadContentFailAction | IErrorMessageAction => {
                    if ((data.status === 401) || (data.status === 403) || (data.status === 418)) {
                        document.location.href = authLink;
                    }

                    if ((data.status >= 400) && (data.status !== 403)
                        && (data.status !== 401) && (data.status !== 418)) {
                        return {
                            type: LOAD_CONTENT_FAIL,
                            payload: data.status,
                            textError: true,
                        }
                    }

                    if (data.text) {
                        return {
                            type: LOAD_CONTENT_FINISH,
                            hash,
                            text: data.text,
                        }
                    }

                    return {
                        type: ERROR_MESSAGE,
                        message: 'LOAD_CONTENT fail',
                    };
                }),
            )
        }),
        catchError((error) => of({
            type: ERROR_MESSAGE,
            message: `LOAD_CONTENT fail ${error.message}`,
        })),
    );

const loadSubscriptionEpic = (action: ActionsObservable<SubscriptionAction>) =>
    action.pipe(
        ofType<SubscriptionAction, ILoadSubscriptionStartAction>(LOAD_SUBSCRIPTION_START),
        mergeMap((a: ILoadSubscriptionStartAction) => {
            const lang = a.payload.lang;
            let subscriptionUrl = '';
            switch (lang) {
                // case (Languages.tj):
                //     subscriptionUrl = `${SUBSCRIPTION_URL}2`;
                //     break;
                case (Languages.en):
                    subscriptionUrl = `${SUBSCRIPTION_URL}3`;
                    break;
                case (Languages.ru):
                default:
                    subscriptionUrl = `${SUBSCRIPTION_URL}1`;
            }

            return from(getText(subscriptionUrl)).pipe (
                map(
                    (data: ITextResponse):
                        ILoadSubscriptionFinishAction
                        | ILoadSubscriptionFailAction
                        | IErrorMessageAction => {
                    if (data.status >= 400) {
                        return {
                            type: LOAD_SUBSCRIPTION_FAIL,
                            payload: data.status,
                            error: true,
                        }
                    }

                    if (data.text) {
                        return {
                            type: LOAD_SUBSCRIPTION_FINISH,
                            lang,
                            text: data.text,
                        }
                    }

                    return {
                        type: ERROR_MESSAGE,
                        message: 'LOAD_SUBSCRIPTION fail',
                    };
                }),
            )
        }),
        catchError((error) => of({
            type: ERROR_MESSAGE,
            message: `LOAD_SUBSCRIPTION fail ${error.message}`,
        })),
    );

const loadFooterEpic = (action: ActionsObservable<FooterAction>) =>
    action.pipe(
        ofType<FooterAction, ILoadFooterStartAction>(LOAD_FOOTER_START),
        mergeMap((a: ILoadFooterStartAction) => {
            const lang = a.payload.lang;
            let footerUrl = '';
            switch (lang) {
                // case (Languages.tj):
                //     footerUrl = `${FOOTER_URL}2`;
                //     break;
                case (Languages.en):
                    footerUrl = `${FOOTER_URL}3`;
                    break;
                case (Languages.ru):
                default:
                    footerUrl = `${FOOTER_URL}1`;
            }
            return from(getText(footerUrl)).pipe (
                map((data: ITextResponse): ILoadFooterFinishAction | ILoadFooterFailAction | IErrorMessageAction => {
                    if (data.status >= 400) {
                        return {
                            type: LOAD_FOOTER_FAIL,
                            payload: data.status,
                            error: true,
                        }
                    }

                    if (data.text) {
                        return {
                            type: LOAD_FOOTER_FINISH,
                            lang,
                            text: data.text,
                        }
                    }

                    return {
                        type: ERROR_MESSAGE,
                        message: 'LOAD_FOOTER fail',
                    };
                }),
            )
        }),
        catchError((error) => of({
            type: ERROR_MESSAGE,
            message: `LOAD_FOOTER fail ${error.message}`,
        })),
    );

const loadOfferEpic = (action: ActionsObservable<OfferAction>) =>
    action.pipe(
        ofType<OfferAction, ILoadOfferStartAction>(LOAD_OFFER_START),
        mergeMap((a: ILoadOfferStartAction) => {
            const lang = a.payload.lang;
            let offerUrl = '';
            switch (lang) {
                // case (Languages.tj):
                //     offerUrl = `${OFFER_URL}2`;
                //     break;
                case (Languages.en):
                    offerUrl = `${OFFER_URL}3`;
                    break;
                case (Languages.ru):
                default:
                    offerUrl = `${OFFER_URL}1`;
            }
            return from(getText(offerUrl)).pipe (
                map((data: ITextResponse): ILoadOfferFinishAction | ILoadOfferFailAction | IErrorMessageAction => {
                    if (data.status >= 400) {
                        return {
                            type: LOAD_OFFER_FAIL,
                            payload: data.status,
                            error: true,
                        }
                    }

                    if (data.text) {
                        return {
                            type: LOAD_OFFER_FINISH,
                            lang,
                            text: data.text,
                        }
                    }

                    return {
                        type: ERROR_MESSAGE,
                        message: 'LOAD_OFFER fail',
                    };
                }),
            )
        }),
        catchError((error) => of({
            type: ERROR_MESSAGE,
            message: `LOAD_OFFER fail ${error.message}`,
        })),
    );

const languageEpic = (action: ActionsObservable<LanguageAction>) =>
    action.pipe(
        ofType<LanguageAction, IChangeLanguageStartAction>(CHANGE_LANGUAGE_START),
        mergeMap((a: IChangeLanguageStartAction) => {
            const lang: Languages = a.payload;
            Cookies.remove('language', {path: ''});
            Cookies.set('language', lang);
            return from(setLang(lang)).pipe(
                map(() => ({
                    type: CHANGE_LANGUAGE_FINISH,
                    payload: lang,
                })),
            )
        }),
        catchError((error) => of({
            type: ERROR_MESSAGE,
            message: `CHANGE_LANGUAGE fail ${error.message}`,
        })),
    );

const checkAuthorizationEpic =
    (action: ActionsObservable<ICheckAuthorizationAction>) =>
    action.pipe(
        ofType<ICheckAuthorizationAction>(CHECK_AUTHORIZATION),
        mergeMap((a: ICheckAuthorizationAction) => {
            return from(getData(CATEGORIES_URL)).pipe(
                tap((data: IDataResponse): void => {
                        if (data.json && !data.json.authorized) {
                            const lang = getCurrentLang();
                            let authLink: string;
                            switch (lang) {
                                // case (Languages.tj):
                                //     authLink = AUTH_LINK_TJ;
                                //     break;
                                case (Languages.en):
                                    authLink = AUTH_LINK_EN;
                                    break;
                                case (Languages.ru):
                                default:
                                    authLink = AUTH_LINK_RU;
                            }
                            Cookies.set('lastUrl', a.payload.routeUrl);
                            document.location.href = authLink;
                        }
                    },
                ),
                mergeMap(() => EMPTY),
            )
        }),
        catchError((error) => of({
            type: ERROR_MESSAGE,
            message: `CHECK_AUTHORIZATION fail ${error.message}`,
        })),
    );

const errorEpic = (action: ActionsObservable<IErrorMessageAction>) =>
    action.pipe(
        ofType<IErrorMessageAction>(ERROR_MESSAGE),
        tap((a: IErrorMessageAction) => {
            console.log(a.message);
        }),
        mergeMap(() => EMPTY),
    );

export const rootEpic =
    combineEpics(
        loadCategoriesEpic,
        loadContentEpic,
        loadFooterEpic,
        loadOfferEpic,
        loadSubscriptionEpic,
        checkAuthorizationEpic,
        languageEpic,
        loadManifestEpic,
        errorEpic,
    );
