import {
    LOAD_MANIFEST_FAIL,
    LOAD_MANIFEST_FINISH,
    LOAD_MANIFEST_START,
    ManifestAction,
} from '../actions/pressContentAction';
import {IPressContentManifest} from '../types/types';

const initialStore: IPressContentManifest = {
    manifestLoaded: false,
    manifests: {},
    manifestError: false,
};

export function manifestReducer(
    state: IPressContentManifest = initialStore,
    action: ManifestAction,
): IPressContentManifest {
    switch (action.type) {
        case LOAD_MANIFEST_START: {
            return {...state, manifestLoaded: false}
        }
        case LOAD_MANIFEST_FINISH: {
            return {
                ...state,
                manifestLoaded: true,
                manifests: {
                    ...state.manifests,
                    [action.hash]: action.payload,
                },
            }
        }
        case LOAD_MANIFEST_FAIL: {
            return{...state, manifestLoaded: false, manifestError: action.manifestError}
        }
    }
    return state;
}
