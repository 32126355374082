import {IClassNameProps} from '@bem-react/core';
import {connect} from 'react-redux';

import {getCurrentLang} from '../../../helpers/currentLanguageHelper';
import {IAppStore, IContentInfo} from '../../../types/types';

import {IPressContentItemStateProps, PressContentItem} from './PressContentItem';

export interface IOwnProps extends IClassNameProps {
    content: IContentInfo;
}

const mapStateToProps = (store: IAppStore, ownProps: IOwnProps): IPressContentItemStateProps => {
    const currentLanguage = getCurrentLang();
    return {
        ...ownProps,
        language: currentLanguage,
        authorised: store.section.authorized,
    }
};

export const PressContentItemContainer = connect(mapStateToProps, null)(PressContentItem);
