import React, {ReactNode, useEffect, useState} from 'react';

import {ClassNameFormatter, cn} from '@bem-react/classname';
import {classnames} from '@bem-react/classnames';
import {Translation} from 'react-i18next';
import Swiper from 'react-id-swiper';
import {SwiperInstance} from 'react-id-swiper';
import LazyLoad from 'react-lazyload';
import {useSelector} from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import {IAppStore, ICategory} from '../../types/types';
import { MusicItemPreview } from '../MusicItemPreview/MusicItemPreview';

import './MusicItem.css';

interface IMusicItem {
    handlePlay(): void;
    handlePause(): void;
}

const musicItem: ClassNameFormatter = cn('MusicItem');

export const MusicItem = (props: IMusicItem) => {

    const [swiperInst, setSwiperInst] = useState<SwiperInstance | null>(null);
    const { handlePlay, handlePause } = props;

    const isMobile = useMediaQuery({ query:  '(max-width: 600px)'});

    const store: IAppStore = useSelector((st: IAppStore) => st);

    const { section: {musicCategories, authorized}, lang, playerInfo: { topNumber } } = store;

    const swiperSettings = {
        scrollbar: {
            el: '.swiper-scrollbar',
            hide: false,
        },
        slidesPerView: 1,
        initialSlide: topNumber,
        getSwiper: setSwiperInst,
    };

    useEffect(() => {
        if (swiperInst) {
            swiperInst.slideTo(topNumber);
        }
    }, [topNumber, swiperInst]);

    const getMusicPreviews = (musicData: ICategory[], withSlide: boolean = false): ReactNode => {
            return musicData.map((category: ICategory, index) => {
                return withSlide ? (
                    <div
                        key={index}
                        className={classnames(musicItem('Slide'), 'swiper-slide')}
                    >
                        <MusicItemPreview
                            playlist={category.contents}
                            alias={category.alias}
                            lang={lang}
                            authorized={!!authorized}
                            handlePlay={handlePlay}
                            handlePause={handlePause}
                        />
                    </div>
                ) : (
                    <MusicItemPreview
                        key={category.id}
                        playlist={category.contents}
                        alias={category.alias}
                        lang={lang}
                        authorized={!!authorized}
                        handlePlay={handlePlay}
                        handlePause={handlePause}
                    />
                )
            });
    }

    return (
        <section
            className={musicItem()}
            id={'music'}
        >
            <div className={musicItem('Wrapper')}>
                <Translation>
                    {
                        (t, { i18n }) =>
                            <h2 className={musicItem('Title')}>{t('Музыка')}</h2>
                    }
                </Translation>
                    <div className={musicItem('ContentWrapper')}>
                        {!isMobile && musicCategories && musicCategories.length
                            ? getMusicPreviews(musicCategories, isMobile)
                            : <></>
                        }
                        {isMobile ?
                            <LazyLoad scroll={true}>
                                <Swiper
                                    {...swiperSettings}
                                >
                                    {musicCategories && musicCategories.length ?
                                        getMusicPreviews(musicCategories, isMobile) : <></>}
                                </Swiper>
                            </LazyLoad>
                            :
                            <></>
                        }
                    </div>
            </div>
        </section>
    );
};
