import React, { Component } from 'react';

import { ClassNameFormatter, cn } from '@bem-react/classname';
import { classnames } from '@bem-react/classnames';
import { IClassNameProps } from '@bem-react/core';
import { Link } from 'react-router-dom';

// import { ENG_LANG, RUS_LANG } from '../../constants/constants';
import { ICategory, Languages } from '../../types/types';
import { CloseButton } from '../Buttons/CloseButton/CLoseButton';
import { HamburgerButton } from '../Buttons/HumburgerButton/HumburgerButton';
import logo from '../Icons/ml_logo.svg';
import { Menu } from '../Menu/Menu';

import './Header.css';

export interface IHeaderState {
    open: boolean;
    selectOpen: boolean;
}

export interface IHeaderDispatchProps {
    onChange(lang: Languages): void;
}

export interface IHeaderStateProps {
    page: string;
    categoriesData: ICategory[];
    categoryAlias?: string;
    language: string;
}

export interface IHeaderProps extends IClassNameProps, IHeaderStateProps, IHeaderDispatchProps {
}

const header: ClassNameFormatter = cn('Header');

export class Header extends Component <IHeaderProps, IHeaderState> {
    state: IHeaderState = {
        open: false,
        selectOpen: false,
    };

    toggleMenu = () => this.setState({open: !this.state.open});
    closeMenu = () => this.setState({open: false});

    changeLang = (lang: Languages) => {
        const { onChange } = this.props;

        onChange(lang);
        this.setState({selectOpen: false});

    }

    render() {
        const { open, selectOpen } = this.state;
        const {categoriesData, page, categoryAlias, language} = this.props;
        // let selectedLanguage: string = '';

        // switch (language) {
        //     case (Languages.tj):
        //         selectedLanguage = TJ_LANG;
        //         break;
        //     case (Languages.en):
        //         selectedLanguage = ENG_LANG;
        //         break;
        //     default:
        //         selectedLanguage = RUS_LANG;
        // }

        return (
            <header
                className={classnames(
                    header({ofPage: page, theme: categoryAlias, visible: open}),
                    this.props.className)}>
                <div className={classnames(header('Wrapper'), 'Wrapper')}>
                    <div className={header('ButtonWrapper')}>
                    <HamburgerButton onClick={this.toggleMenu}
                                     className={classnames(header('HamburgerButton', {visible: !this.state.open}))}/>
                    <CloseButton onClose={this.toggleMenu}
                                 className={classnames(header('CloseButton', {visible: this.state.open}))}/>
                    </div>
                    <div className={header('LogoContainer')}>
                        <Link to='/' className={header('Link')}>
                            <img src={logo} className={header('Logo')}
                                 alt='logo'/>
                        </Link>
                    </div>
                    <Menu
                        className={classnames(header('Menu', {visible: open}))}
                        page={page}
                        categoriesData={categoriesData}
                        onClose={this.closeMenu}
                    />
                    {/*<div className={header('Select')}>*/}
                    {/*    <div*/}
                    {/*        className={header('SelectItem', {state: 'selected'})}*/}
                    {/*        onClick={() => this.setState({ selectOpen: !this.state.selectOpen })}*/}
                    {/*    >*/}
                    {/*        {selectedLanguage}*/}
                    {/*    </div>*/}
                    {/*    <ul className={header('SelectList', {visible: selectOpen})}>*/}
                    {/*        <li*/}
                    {/*            className={header('SelectItem', {active: language === Languages.ru})}*/}
                    {/*            onClick={() => {*/}
                    {/*                this.changeLang(Languages.ru);*/}
                    {/*            }}>*/}
                    {/*            <span>{RUS_LANG}</span>*/}
                    {/*        </li>*/}
                    {/*        <li*/}
                    {/*            className={header('SelectItem', {active: language === Languages.tj})}*/}
                    {/*            onClick={() => {*/}
                    {/*                this.changeLang(Languages.tj);*/}
                    {/*            }}>*/}
                    {/*            <span>{TJ_LANG}</span>*/}
                    {/*        </li>*/}
                    {/*        <li*/}
                    {/*            className={header('SelectItem', {active: language === Languages.en})}*/}
                    {/*            onClick={() => {*/}
                    {/*                this.changeLang(Languages.en);*/}
                    {/*            }}>*/}
                    {/*            <span>{ENG_LANG}</span>*/}
                    {/*        </li>*/}
                    {/*    </ul>*/}
                    {/*</div>*/}
                </div>
            </header>
        );
    }
}
