import i18n, { TFunction } from 'i18next';
import * as Cookies from 'js-cookie';

import { DEFAULT_LANG } from '../constants/constants';
import { IMultiName, Languages } from '../types/types';

let currentLang: string;
const langFromCookies: string | undefined = Cookies.get('language');

langFromCookies ? currentLang = langFromCookies : currentLang = DEFAULT_LANG;

// функция изменения языка
export function setLang(lang: Languages): Promise<TFunction> {
    currentLang = lang;
    Cookies.set('language', lang);
    return i18n.changeLanguage(currentLang);
}

// функция выбора нужного языка из данных
export function chooseAndTranslate(obj: IMultiName) {
    switch (currentLang) {
        // case (Languages.tj):
        //     return obj.tj;
        case (Languages.en):
            return obj.en;
        case (Languages.ru):
            return Languages.ru;
        default:
            return obj.ru;
    }
}

// функция получения текущего языка
export function getCurrentLang() {
    return currentLang;
}

// функция получения типа текущего языка
export function getCurrentLangType() {
    switch (currentLang) {
        // case (Languages.tj):
        //     return Languages.tj;
        case (Languages.en):
            return Languages.en;
        case (Languages.ru):
            return Languages.ru;
        default:
            return Languages.ru;
    }
}
