import React from 'react';

import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';

import {AppContainer} from './Components/App/AppContainer';
import './i18n';
import {initStore} from './store';
import {Languages} from './types/types';

import './index.css';

ReactDOM.render(
    (
        <Provider store={initStore()}>
        <AppContainer onInit={() => {}} onChange={(lang: Languages) => {}} />
    </Provider>
    ),
    document.getElementById('root'));
