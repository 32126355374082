import React, { Component } from 'react';

import {ClassNameFormatter, cn} from '@bem-react/classname';
import {classnames} from '@bem-react/classnames';
import {IClassNameProps} from '@bem-react/core';
import {Translation} from 'react-i18next';
import {Link} from 'react-router-dom';

import {AMOUNT_OF_CONTENTITEMS_FOR_MAINPAGE, PRESS_ALIAS} from '../../constants/constants';
import {checkContentProps} from '../../helpers/checkContentProps';
import {getFullCards, getPartialCards} from '../../helpers/contentHelper';
import {getCategoryButtonText} from '../../helpers/getCategoryButtonText';
import {getJournalIndex} from '../../helpers/getJournalIndex';
import {getlatestJournals} from '../../helpers/getlatestJournals';
import {getCategoryLink} from '../../helpers/getLinks';
import {IContentInfo} from '../../types/types';
import {ContentListFull} from '../ContentListFull/ContentListFull';
import {ContentListPartial} from '../ContentListPartial/ContentListPartial';
import {PressContentList} from '../Press/PressContentList/PressContentList';
import {PressContentSlider} from '../Press/PressContentSlider/PressContentSlider';

import './CategoryItem.css';

export interface ICategoryItemProps extends IClassNameProps {
    name: string;
    id: number;
    contentData: IContentInfo[];
    alias: string;
    page: string;
}

const categoryItem: ClassNameFormatter = cn('CategoryItem');

export class CategoryItem extends Component<ICategoryItemProps> {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    renderContent = () => {
        const {contentData, alias} = this.props;

        if (alias === PRESS_ALIAS && this.props.page === 'main') {
            const journalIndex = getJournalIndex(contentData);
            const latestJournals = getlatestJournals(journalIndex);

            return (
                <PressContentSlider pressContent={latestJournals} categoryAlias={alias} page={this.props.page}/>
            )
        } else if (alias === PRESS_ALIAS && this.props.page !== 'main') {
            return (
                <PressContentList pressContent={contentData} alias={alias} page={this.props.page}/>
            )
        } else if (alias !== PRESS_ALIAS && this.props.page === 'main') {
            const contentCardsPart = getPartialCards(contentData);

            return (
                <div className={categoryItem('ContentWrap')}>
                    <ContentListPartial cards={contentCardsPart} alias={alias} page={this.props.page} />
                </div>
            )
        } else {
            const contentCards = getFullCards(contentData);

            return (
                <div className={categoryItem('ContentWrap')}>
                    <ContentListFull cards={contentCards} alias={alias} page={this.props.page} />
                </div>
            )
        }
    };

    render() {
        const {page} = this.props;
        const linkToCategory = getCategoryLink(this.props.alias);
        const theme = (this.props.alias === PRESS_ALIAS) ? 'dark' : 'light';
        const buttonText = getCategoryButtonText(this.props.alias);

        return (
            <section
                className={classnames(categoryItem({theme, ofPage: page}))}
            >
                <div className={categoryItem('Wrapper')}>
                    <div className={categoryItem('LinkWrap', {})}>
                        <Link to={'/'} className={categoryItem('HomeLink', { ofPage: page })}>
                            <div className={categoryItem('HomeLink-Btn', { size: 'S' })} />
                            <Translation>
                                {
                                    (t, { i18n }) =>
                                        <div className={categoryItem('HomeLink-Btn', { size: 'L' })} />
                                }
                            </Translation>

                        </Link>
                        <Link
                            to={linkToCategory}
                            className={classnames(categoryItem('Link', { ofPage: page }), categoryItem('TitleLink'))}>
                            <Translation>
                                {(t, { i18n }) =>
                                    <h2 className={classnames(categoryItem('Title'), categoryItem('type_' + this.props.alias))}>
                                        {t(this.props.name)}
                                    </h2>
                                }
                            </Translation>
                            <div className={categoryItem('CategoryLink', {})} />
                        </Link>
                    </div>
                    {this.renderContent()}
                    <div className={categoryItem('MoreButtonWrapper')}>
                        <Link
                            to={linkToCategory}
                            className={classnames(categoryItem('Link'), categoryItem('MoreButtonLink'))}
                        >
                            <Translation>
                                {(t, { i18n }) =>
                                        <div className={categoryItem('MoreButton')}>{t('Ещё по теме')}</div>

                                }
                            </Translation>
                        </Link>
                    </div>
                </div>
            </section>
        )
    };
}
